var _paq = window._paq = window._paq || [];
import "./tracking_settings";
$(window).on('load', function () {
    if (frappe.session.user != "Guest") {
        _paq.push(['setUserId', frappe.session.user]);
    }
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = window.u + 'matomo.js'; s.parentNode.insertBefore(g, s);

});
